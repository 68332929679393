import './ProjectResults.css';
import Markdown from 'react-markdown'


function ProjectResults({results, resultsImage, outcome}) {
    return (
        <section className="project-results section--light">
            <div className="project-results__title">RESULTS</div>
            <div className="project-results__body">
                <Markdown>{outcome}</Markdown>
            </div>
        </section>
    );
}

export default ProjectResults;