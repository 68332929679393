import './CaseStudy.css';

function CaseStudy({image, id, project_slug, title, description, background}) {
    let sectionType;
    if (background === 'light'){
        sectionType = 'section--light'
    } else if (background === 'dark') {
        sectionType = 'section--dark'
    } else {
        sectionType = 'section--pink'
    }

    return (
        <div className={`case-study ${sectionType}`}>
                <div className="case-study__topline">
                    <div className="case-study__id">{id}</div>
                    <div className="case-study__title">{title}</div>
                </div>
                <a href={`/projects/${project_slug}`}>
                    <img className="case-study__image" src={image}/>
                </a>
                <div className="case-study__description">{description}</div>

        </div>
    );
}

export default CaseStudy;