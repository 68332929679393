// @ts-ignore
import ProcessStack from "./ProcessStack/ProcessStack.tsx";

import './Process.css';

function Process() {
    return <section className="process section--light">
        <div className="process__title">OUR PROCESS</div>
        <ProcessStack></ProcessStack>
    </section>
}

export default Process