// @ts-ignore
import thumbnail_1 from "../../assets/images/projects/thumbnail-1.jpeg";
// @ts-ignore
import thumbnail_2 from "../../assets/images/projects/thumbnail-2.jpeg";
// @ts-ignore
import thumbnail_3 from "../../assets/images/projects/thumbnail-3.jpeg";

// @ts-ignore
import coinbase_hero_image from "../../assets/images/projects/coinbase/hero_image.jpeg"

// @ts-ignore
import coinbase_divider_image from "../../assets/images/projects/coinbase/divider_image.jpeg"

// @ts-ignore
import fpg_hero_image from "../../assets/images/projects/fpg/hero_image.png"

// @ts-ignore
import fpg_divider_image from "../../assets/images/projects/fpg/divider_image.jpeg"

// @ts-ignore
import digraph_hero_image from "../../assets/images/projects/digraph/hero_image.jpeg"

// @ts-ignore
import digraph_divider_image from "../../assets/images/projects/digraph/divider_image.jpeg"

let ProjectsData = {
    "digraph": {
        id: 1,
        title: "DIGRAPH",
        summary: "AUTONOMOUS AI AGENT FOR ENHANCED CLOUD SECURITY",
        thumbnail: thumbnail_1,
        link: "digraph",
        prev: "floating-point-group",
        next: "coinbase",
        details: {
            hero_image: digraph_hero_image,
            divider_image: digraph_divider_image,
        },
        sections: {
            goal: "Cliff and James, founders of Digraph, aimed to create a *truly differentiated product* in the competitive cybersecurity market by leveraging recent innovations in Large Language Models. Their existing product offered a suite of automated compliance tools that notified clients of insecure code or infrastructure assets within their code repositories and cloud environments.\n",
            gap: "While the initial Digraph product was compelling, several Cloud Security Posture Management (CSPM) products with similar offerings were already in the market. The founders saw an opportunity to harness generative AI and their in-house expertise to develop a product that could not only identify and alert engineering teams about security issues in code and cloud resources but also automatically generate code and policy hotfixes to address these issues.\n",
            approach: "We partnered with Digraph for 5 months, navigating ideation, implementation, and product launch. A Fortuna Senior Software Engineer was embedded with the Digraph team, working directly with the CTO to develop the CSPM product.\n" +
                "\n" +
                "The collaboration followed a structured three-phase life-cycle:\n\n" +
                "\n" +
                "Analysis and Validation:\n" +
                "The Fortuna team dedicated several weeks to evaluating open-source libraries to identify a stack that best met Digraph’s needs, focusing on platform interoperability, security, and performance. Cloudquery was chosen for its support of GCloud, AWS, and Azure, its scalable architecture, and alignment with industry security best practices.\n" +
                "\n" +
                "Architecture Definition:\n" +
                "Subsequently, the teams defined a high-level event-driven and pipelined architecture for the product. Emphasis was placed on isolating client access credentials, network layer, and resource identifiers for security and compliance. The architecture utilized a configurable runtime to interface with specific cloud providers, scan for specified resource types, and export scan outputs to a per-tenant database instance.\n" +
                "\n" +
                "The scan artifacts (representing the latest state of a client’s cloud infrastructure) were then fed into a custom machine learning pipeline to detect anomalies, configurations violating client-defined security requirements, and drift between at-rest Terraform infrastructure definitions and live deployment states. Any errors identified in the first step of the pipeline were passed to a retrieval-augmented generation module, which used OpenAI’s GPT-3.5-turbo and custom prompts to generate code fixes via GitHub. Special attention was given to short and long-term caching of OpenAI responses to mitigate rate limiting, ensure effective use of request context windows, and meet Digraph’s performance SLAs.\n" +
                "\n" +
                "The product was deployed on Google Cloud to leverage services such as Cloud Run, Cloud Scheduler, Artifact Repository, and Secrets Manager, ensuring a straightforward deployment process.\n" +
                "\n" +
                "Implementation and Iteration:\n" +
                "The collaboration with Digraph’s global engineering team was seamless over the 5-month period. With employees located on both coasts of the United States, as well as in Spain, Romania, and Kenya, Fortuna’s remote-first culture facilitated effective integration into Digraph’s asynchronous work environment. Our focus on timeliness and quality enabled us to incorporate critical integration testing and deployment patterns for faster iteration cycles.\n",
            outcome: "The joint efforts led to the successful development and deployment of the CSPM product. The innovative market opportunity identified by Digraph's founders resulted in the company’s acquisition by Datadog in November 2023. This acquisition highlighted the product’s value proposition and the effectiveness of our partnership-driven product development approach.",
        }
    },
    "coinbase": {
        id: 2,
        title: "COINBASE",
        summary: "SEAMLESS MIGRATION OF 1.5 BILLION PRICE RECORDS WITH ZERO DOWNTIME",
        thumbnail: thumbnail_2,
        link: "coinbase",
        prev: "digraph",
        next: "floating-point-group",
        details: {
            hero_image: coinbase_hero_image,
            divider_image: coinbase_divider_image,
        },
        sections: {
            client_overview: "Coinbase is a San Francisco based cryptocurrency exchange, the world’s safest and most trusted platform to buy, sell and manage crypto assets.\n",
            goal: "Coinbase saw a period of hypergrowth between 2016 - 2020, trending with the increasing popularity of crypto assets and the rising price of Bitcoin. The company had done a great job building robust, scalable, and well-engineered products, but their API performance would often degrade during periods of market volatility, when large amounts of users would try to access the platform concurrently within a short timeframe.\n" +
                "\n" +
                "The company needed to build a robust and reliable API layer that would be resilient to bursty traffic patterns, enabling Coinbase products to maintain uptime during the periods when users wanted to access them most.\n",
            gap: "The Coinbase API was backed by a cloud-scale MongoDB deployment, which served as the source of truth and primary datastore for several years. Over time the retail-focused business unit expanded to serving 3000 crypto assets and billions of price records to millions of users. This increased growth led to the MongoDB cluster struggling during peak traffic, often reaching a resource saturation point (CPU/Memory) and increasing per-request latency significantly. This latency issue adversely affected user experience across web and mobile apps, leading to significant downtime during peak traffic periods when the number of on-site users would surge from 20 to 30 times the average.\n",
            approach: "To address this, Fortunas CTO Phil Opaola led a yearlong project to migrate 1.5 billion price records from MongoDB to DynamoDB without any downtime for customer-facing services.\n" +
                "\n" +
                "Migration Scope:\n" +
                "The migration to DynamoDB aimed to relieve pressure on the Assets cluster, utilizing DynamoDB's suitability for time series data and dynamic request patterns. The price data was housed behind a gRPC Prices microservice written in Golang. The existing Ruby monolith was adapted to proxy traffic to the new Golang service.\n" +
                "\n" +
                "API Service Requirements:\n" +
                "The new API service needed to align seamlessly with the existing API, supporting an extensive range of fiat currency conversions and aggregated price information across different time resolutions (e.g., minute, hour, day, week). A combination of feature-flagged backends, GitHub Scientist for controlled experimentation, and Datadog dashboards with PagerDuty integrations ensured a safe and controlled staged rollout across pre-production and production environments.\n",
            outcome: "\n" +
                "Results:\n" +
                "Improved Monitoring:\n" +
                "Enhanced monitoring capabilities allowed the retail business unit to integrate new products confidently on top of the Prices service.\n" +
                "\n" +
                "Scalability Achieved:\n" +
                "Coinbase's Prices API successfully scaled to support over 8,000 assets as of summer 2022.\n" +
                "Cluster health metrics all showed improvement and were maintained at healthy levels, even during peak traffic, resulting in fewer downtime incidents.\n",
        }
    },
    "floating-point-group": {
        id: 3,
        title: "FLOATING POINT GROUP",
        summary: "CRYPTO TRADING PLATFORM - BEST PRACTICES IN SCALING BEYOND MVP",
        thumbnail: thumbnail_3,
        link: "floating-point-group",
        prev: "coinbase",
        next: "digraph",
        details: {
            hero_image: fpg_hero_image,
            divider_image: fpg_divider_image,
        },
        sections: {
            client_overview: "Floating Point Group (FPG) is a New York City-based series-B venture-funded cryptocurrency platform catering to institutional investors. FPG offers trade execution and asset custody services for banks, hedge funds, family offices, and venture capital firms.\n",
            goal: "After having found product-market fit and proving their core thesis, FPG was ready to scale their engineering systems to enable them to acquire their next 100 million dollars in assets under management. This would involve removing technical debt accumulated during the initial prototyping phase, significantly refactoring their trade execution APIs, and introducing improvements to their security posture that would enable them to attain SOC 2 compliance.\n",
            gap: "As FPG was in hyper-growth mode, with all of their existing engineering resources focused on expanding the number of blockchains and exchanges they serviced, the founders realized there was a need to work with an outside partner to scale their trading API. They wanted to allow their engineering team to not only maintain velocity with their current projects, but also wanted to bring in outside expertise and a different perspective on the trading API. This is where Fortuna comes in.\n",
            approach: "Recognizing FPG's need to scale post-product-market fit, Fortuna was engaged as a strategic partner. One of our engineers was embedded within FPG's development team for a period of 3 months, operating in a dual capacity as an individual contributor as well as an architectural consultant leveraging prior experience building cryptocurrency trading products.\n" +
                "\n" +
                "An initial immersion period was demarcated in order for Fortuna to get familiarized with FPG's existing products, technical architecture, and engineering practices. During this time our engineer worked on small and medium sized tickets across the various FPG products, identified the project stakeholders across the company, and took the time to understand their primary goals and win conditions for the trading API refactor.\n" +
                "\n" +
                "The Fortuna engineer also spent many hours with the early engineers that built the trading API (which included one of FPG’s co-founders) investigating the constraints they were working under at the time, tradeoffs they had to make, and other potential approaches they’d have taken based on hindsight. \n" +
                "\n" +
                "During this exploration process, the engineer also facilitated the integration of new cryptocurrency blockchains and exchanges into FPG's existing codebase, and introduced a more standardized API design process to prevent expectation drift between frontend and backend teams, as well as identified and resolved security issues within the codebase around how certain production credentials were stored.\n" +
                "\n" +
                "Additionally, guidance was provided on transitioning from a custom internal serverless framework to fast.ly. The decision to adopt fast.ly over alternatives like gRPC (or continuing with the bespoke solution) were informed by the engineer's experience and validated through direct involvement in the fast.ly prototype.\n" +
                "\n" +
                "After two months of immersion and investigation, the engineer presented FPG with an engineering spec outlining a new trading API design. The proposal would enable FPG to implement a v2 API with zero downtime in the migration, create a parameterizable integration testing approach (the existing implementation was flaky, time-intensive, and costly as it would actually make live requests to exchange platforms), and transition from a serverless architecture to using fast.ly’s asynchronous processing capabilities.\n",
            outcome: "The collaborative efforts resulted in a revamped trading API design, aligning with industry best practices and positioning FPG for sustainable growth. The implementation of best practices not only addressed technical debt but also enhanced the platform's security, reliability, and scalability. This strategic initiative laid a robust foundation for Floating Point Group to navigate the evolving landscape of cryptocurrency trading.\n",
        }
    },
}

export default ProjectsData;