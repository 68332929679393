// @ts-ignore
import ProjectsGrid from './ProjectsGrid/ProjectsGrid.tsx';

function Projects () {
    return (
        <section className="projects section--light">
            <div className="projects__title">PROJECTS</div>
            <ProjectsGrid></ProjectsGrid>
        </section>
    );
}

export default Projects;