// @ts-ignore
import ProjectsData from "../components/Projects/ProjectsData.ts";

export function loader( params ) {
    let projectId = params.params.projectId;
    let project = ProjectsData[projectId];

    console.log(projectId);
    console.log(project);

    return project;
}