// @ts-ignore
import ProjectsData from "../../Projects/ProjectsData.ts";
// @ts-ignore
import ProjectCard from "../../Projects/ProjectCard/ProjectCard.tsx";

import './ProjectNav.css';

function ProjectNav({prevId, nextId}) {
    let prevProject = ProjectsData[prevId];
    let nextProject = ProjectsData[nextId];

    return <section className="section--light">
        <div className="projects-nav">
            <div className="projects-nav-card">
                <a href={prevProject.link} className="projects-nav-card__link">PREVIOUS</a>
                <ProjectCard
                    key={prevProject.id}
                    title={prevProject.title}
                    description={prevProject.description}
                    thumbnail={prevProject.thumbnail}
                    link={prevProject.link}
                />
            </div>
            <div className="projects-nav-card">
                <a href={nextProject.link} className="projects-nav-card__link">NEXT</a>
                <ProjectCard
                    key={nextProject.id}
                    title={nextProject.title}
                    description={nextProject.description}
                    thumbnail={nextProject.thumbnail}
                    link={nextProject.link}
                />
            </div>
        </div>
    </section>
}

export default ProjectNav;