// @ts-ignore
import CultureTeamMember from "../CultureTeamMember/CultureTeamMember.tsx";

import './CultureTeam.css';

// @ts-ignore
import phil from '../../../assets/images/culture/phil-4.jpeg';
function CultureTeam() {
    let team = [
        {
            name: "Phil Opaola",
            role: "Founder",
            thumbnail: phil
        }
    ];

    return <section className="culture-team">
        {team.map((member, index) => (
            <CultureTeamMember
                key={index}
                name={member.name}
                role={member.role}
                thumbnail={member.thumbnail}
            ></CultureTeamMember>
        ))}
    </section>
}

export default CultureTeam;