// @ts-ignore
import ProjectCard from "../ProjectCard/ProjectCard.tsx";
// @ts-ignore
import ProjectsData from "../ProjectsData.ts";

import './ProjectsGrid.css';

function ProjectsGrid () {
    return <div className="projects-grid">
        {
            ["digraph", "coinbase", "floating-point-group"].map(
                (project) => {
                    let data = ProjectsData[project];
                    return <ProjectCard
                        key={data.id}
                        title={data.title}
                        description={data.description}
                        thumbnail={data.thumbnail}
                        link={data.link}
                    />
                }
            )
        }
    </div>
}

export default ProjectsGrid;