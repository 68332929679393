// @ts-ignore
import Footer from '../../components/Footer/Footer.tsx' // TODO @philopaola - add absolute path imports
// @ts-ignore
import Culture from "../../components/Culture/Culture.tsx";

const CulturePage = () => {
    return <>
        <Culture></Culture>
        <Footer></Footer>
    </>;
};

export default CulturePage;
