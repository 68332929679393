//@ts-ignore
import ProcessPhase from "../ProcessPhase/ProcessPhase.tsx";
//@ts-ignore
import process_discover from '../../../assets/images/process/discover.svg';
//@ts-ignore
import process_define from '../../../assets/images/process/define.svg';
//@ts-ignore
import process_dive from '../../../assets/images/process/develop.svg';
//@ts-ignore
import process_deliver from '../../../assets/images/process/deliver.svg';

import './ProcessStack.css';

function ProcessStack() {
    return <section className="process-stack">
        <ProcessPhase
            key={1}
            name="DISCOVER"
            icon_path={process_discover}
            description="We explore your business and product to better understand your vision and current challenges."
        ></ProcessPhase>
        <ProcessPhase
            key={2}
            name="DEFINE"
            icon_path={process_define}
            description={
                "We establish a project outcome with clear milestones, timelines, and a focused scope of " +
                "work."
            }
        ></ProcessPhase>
        <ProcessPhase
            key={3}
            name="DIVE"
            icon_path={process_dive}
            description="We dive into the implementation, writing code, defining assets and building scalable robust infrastructure to support the defined outcome."
        ></ProcessPhase>
        <ProcessPhase
            key={4}
            name="DELIVER"
            icon_path={process_deliver}
            description={
                "By iterating quickly and regularly checking in with clients, we ensure that the final outcomes align with both business and engineering goals."
            }
        ></ProcessPhase>
    </section>
}

export default ProcessStack;