// @ts-ignore
import CaseStudy from "./CaseStudy/CaseStudy.tsx";

// @ts-ignore
import cspm_image from '../../assets/images/projects/digraph/image 1.jpg';

// @ts-ignore
import fpg_image from '../../assets/images/projects/fpg/image 2.jpg';

// @ts-ignore
import coinbase_image from '../../assets/images/projects/coinbase/image 3.jpg';

import './CaseStudies.css';

function CaseStudies() {
    return <div className="case-studies">
        <CaseStudy
            background='dark'
            image={coinbase_image}
            id='00-1'
            title='FPG'
            project_slug='floating-point-group'
            description='ELEVATING CRYPTOCURRENCY TRADING PLATFORM - BEST PRACTICES IN SCALING BEYOND MVP'
        ></CaseStudy>
        <CaseStudy
            background='light'
            image={cspm_image}
            id='00-2'
            title='DIGRAPH'
            project_slug='digraph'
            description='AGILE PRODUCT DEVELOPMENT FOR ENHANCED CLOUD SECURITY POSTURE MANAGEMENT'
        ></CaseStudy>
        <CaseStudy
            background='pink'
            image={fpg_image}
            id='00-3'
            title='COINBASE'
            project_slug='coinbase'
            description='COINBASE - SEAMLESS MIGRATION OF 1.5 BILLION PRICE RECORDS WITH ZERO DOWNTIME'
        ></CaseStudy>
    </div>
}

export default CaseStudies;