import './ProjectCard.css';

function ProjectCard ({title, description, thumbnail, link}) {
    let url = "/projects/" + link;

    return <div className="project-card">
        <a href={url} className="project-card__link">
            <img src={thumbnail} alt={description} className="project-card__thumbnail"/>
        </a>
        <br/>
        <a className="project-card__title" href={url}>{title}</a>
    </div>
}

export default ProjectCard;