//@ts-ignore
import star_white from '../../../assets/images/star-white.svg'

import './ProjectOverview.css';

function ProjectOverview({title, summary, heroImage}) {
    return (
        <section className="project-description section--dark">
            <div className="project-description__title">{title}</div>
            <div className="project-description__summary">{summary}</div>
            <div className="project-description__logo-box">
                <img src={star_white} width="180px" height="180px"/>
            </div>
            <div className="project-description__images-box">
                <img src={heroImage} width="60%"/>
            </div>
        </section>


    );
}

export default ProjectOverview;