import './Header.css';
import {useEffect, useState} from "react";

function Header() {
    const colorBlack = 'var(--black)';
    const colorWhite = 'var(--white)';
    // .navbar top: 2.5rem; without 1 pixel
    const navbarTopOffset = 2.5 * parseFloat(getComputedStyle(document.documentElement).fontSize) - 1;

    const [textColor, setTextColor] = useState(colorBlack);

    const handleTextColorChange = () => {
        let topNode = document.elementFromPoint(10, navbarTopOffset);
        while (topNode.parentElement && !(topNode.classList.contains('section--dark') || topNode.classList.contains('section--light') || topNode.classList.contains('section--pink'))) {
            topNode = topNode.parentElement;
        }
        setTextColor(topNode.classList.contains('section--dark') ? colorWhite : colorBlack);
    };

    useEffect(() => {
        handleTextColorChange();
        window.addEventListener('scroll', handleTextColorChange);
        return () => {
            window.removeEventListener('scroll', handleTextColorChange);
        };
    }, []);


    return <nav className="navbar">
        <a href="/projects" className="navbar__link" style={{color: textColor}}>PROJECTS</a>
        <a href="/" className="navbar__link navbar__link--emphasized" style={{color: textColor}}>FORTUNA</a>
        <a href="/culture" className="navbar__link" style={{color: textColor}}>CULTURE</a>
    </nav>
}

export default Header