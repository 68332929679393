import './ProjectProblem.css';
import SimpleParallax from "simple-parallax-js";
import Markdown from 'react-markdown'

function ProjectProblem({dividerImage, migrationScope, apiRequirements, goal, gap}) {

    return (
        <section className="project-problem section--dark">
            <div className="project-problem__title">PROBLEM</div>
            <div className="project-problem__description"><Markdown>{goal}</Markdown></div>
            <div className="project-problem__description"><Markdown>{gap}</Markdown></div>
            <div className="project-problem-divider">
                <SimpleParallax>
                    <img className="project-problem-divider__image section--light" loading="lazy" src={dividerImage}
                         alt="Coinbase Price Chart" sizes="100vw"/>
                </SimpleParallax>
            </div>
        </section>
    );
}

export default ProjectProblem;