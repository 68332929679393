import './ProcessPhase.css';

function ProcessPhase({name, icon_path, description}){
    return <section className="process-phase">
        <div className="process-phase__name">{name}</div>
        <div className="process-phase__logo-box">
            <img src={icon_path}/>
        </div>
        <p className="process-phase__description">{description}</p>
    </section>
}

export default ProcessPhase