// @ts-ignore
import Project from '../../components/Project/Project.tsx';
// @ts-ignore
import Footer from '../../components/Footer/Footer.tsx';

import '../../components/Project/Project.css';

const ProjectPage = () => {
    return <>
        <Project></Project>
        <Footer></Footer>
    </>;
}

export default ProjectPage;