// @ts-ignore
import Projects from '../../components/Projects/Projects.tsx';
// @ts-ignore
import Footer from '../../components/Footer/Footer.tsx';

import '../../components/Projects/Projects.css';

const ProjectsPage = () => {
    return <>
        <Projects></Projects>
        <Footer></Footer>
    </>;
};

export default ProjectsPage;
