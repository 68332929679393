// @ts-ignore
import Hero from "../../components/Hero/Hero.tsx";
// @ts-ignore
import CaseStudies from '../../components/CaseStudies/CaseStudies.tsx'
// @ts-ignore
import Expertise from '../../components/Expertise/Expertise.tsx'
// @ts-ignore
import Process from '../../components/Process/Process.tsx'
// @ts-ignore
import Footer from '../../components/Footer/Footer.tsx'


const HomePage = () => {
    return <>
        <Hero></Hero>
        <CaseStudies></CaseStudies>
        <Expertise></Expertise>
        <Process></Process>
        <Footer></Footer>
    </>;
};

export default HomePage;
