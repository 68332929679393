import {
    BrowserRouter as Router,
    Routes,
    Route, createBrowserRouter, RouterProvider,
} from "react-router-dom";

import Home from "./pages/Home/Home.tsx";
import ProjectsPage from "./pages/Projects/Projects.tsx";
import Header from './components/Header/Header.tsx';
import Culture from "./pages/Culture/Culture.tsx";
import ProjectPage from './pages/Project/Project.tsx';
import { loader } from "./routes/root.tsx";

import './components/Common/Common.css';

const router = createBrowserRouter([
    { path: '/', element: <Home/> },
    { path: 'projects', element: <ProjectsPage /> },
    {
        path: 'projects/:projectId',
        element: <ProjectPage/>,
        loader: loader,
    },
    { path: 'culture', element: <Culture /> },
]);


function App() {
    return (
        <>
            <Header />
            <RouterProvider router={router} />
        </>
    );
}

export default App;