import './CultureTeamMember.css';

function CultureTeamMember({name, role, thumbnail}) {
    return (
        <div className="culture-team-member">
            <img className="culture-team-member__thumbnail" src={thumbnail} alt={name}/>
            <br/>
            <div className="culture-team-member__wrapper">
                <div className="culture-team-member__name">{name}</div>
                <div className="culture-team-member__role">{role}</div>
            </div>
        </div>
    );
}

export default CultureTeamMember;