// @ts-ignore
import ProjectOverview from "./ProjectOverview/ProjectOverview.tsx";
// @ts-ignore
import ProjectProblem from "./ProjectProblem/ProjectProblem.tsx";
// @ts-ignore
import ProjectApproach from "./ProjectImplementation/ProjectApproach.tsx";
// @ts-ignore
import ProjectResults from "./ProjectResults/ProjectResults.tsx";
// @ts-ignore
import ProjectLessonsLearned from "./ProjectLessonsLearned/ProjectLessonsLearned.tsx";
// @ts-ignore
import ProjectNav from './ProjectNav/ProjectNav.tsx';

import {useLoaderData} from "react-router-dom";

function Project() {
    const project = useLoaderData();

    return (
        <section className="project">
            <ProjectOverview
                title={project.title}
                summary={project.summary}
                heroImage={project.details.hero_image}
            ></ProjectOverview>
            <ProjectProblem  // gap
                goal={project.sections.goal}
                gap={project.sections.gap}
                dividerImage={project.details.divider_image}
                migrationScope={project.details.migration_scope}
                apiRequirements={project.details.api_requirements}
            ></ProjectProblem>
            <ProjectApproach  // approach
                approach={project.sections.approach}
            ></ProjectApproach>
            <ProjectResults  // outcome
                results={project.details.results}
                resultsImage={project.details.results_image}
                outcome={project.sections.outcome}
            ></ProjectResults>
            <ProjectNav
                prevId={project.prev}
                nextId={project.next}
            ></ProjectNav>
        </section>
    );
}

export default Project;