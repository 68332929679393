import './Footer.css';
//@ts-ignore
import star_black from '../../assets/images/star-black.svg'

function Footer() {
    return (
        <footer className="footer">
            <div className="footer__top section--light">
                <div className="footer__logo-box">
                    <img src={star_black}/>
                </div>
                <div className="footer__cta">
                    LET'S<br/>COLLABORATE<br/>DROP US A LINE
                </div>
            </div>
            <div className="footer__bottom section--dark">
                <div className="footer__about">Fortuna favors the bold</div>

                <div className="footer__contact-info">
                    hello@fortuna.agency
                </div>
                <div className="footer__copyright">
                    @ 2024 Fortuna - All rights reserved
                </div>
            </div>
        </footer>
    );
}

export default Footer;