// @ts-ignore
import ExpertiseCard from "../ExpertiseCard/ExpertiseCard.tsx";
import './ExpertiseStack.css';

function ExpertiseStack() {
    let capabilities = [
        {
            title: "MONITORING & OBSERVABILITY",
            description: "Fortuna creates customized monitoring and observability solutions to ensure continuous visibility into system health. Our tools proactively detect emerging issues, streamline incident management, and enhance overall service reliability."
        },
        {
            title: "LEGACY SYSTEM MODERNIZATION",
            description: "Fortuna helps clients modernize legacy systems to meet their evolving engineering standards and product requirements. We guide teams through identifying and addressing major sources of technical debt, performing zero-downtime dependency migrations, and refactoring code to align with each client's unique and evolving internal standards for architecture, design, and infrastructure. Our phased approach prioritizes high-impact improvements that support long-term technical goals while maintaining system stability and engineering team velocity.",

        },
        {
            title: "TRAINING & EDUCATION",
            description: "Fortuna provides training workshops to equip teams with the skills to build secure and reliable fintech and blockchain-enabled products. We offer two specialized programs: one focused on secure smart contract development in Solidity, and another on reliability and security best practices for distributed backend systems handling financial data. Each workshop combines theory, hands-on exercises, and practical applications tailored to the real-world challenges our clients face, ensuring that learning is both actionable and relevant.",
        },
    ]

    return (
        <section className="expertise-stack">
            {capabilities.map((capability, idx) => (
                <ExpertiseCard key={idx} index={idx} title={capability.title} description={capability.description} />
            ))}
        </section>
    );

}

export default ExpertiseStack