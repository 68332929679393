// @ts-ignore
import CultureTeam from './CultureTeam/CultureTeam.tsx';

import './Culture.css';

function Culture() {
    return (
        <div className="culture section--dark">
            <div className="culture__title">CULTURE</div>
            <div className="culture__headline">
                Quality is our calling card
            </div>
            <CultureTeam></CultureTeam>
            <div className="culture__copy">
                Founded in 2022, Fortuna is shaping the future of fintech with a simple ethos:
                we help clients keep their systems secure and their customers satisfied. With each project, we guide
                clients in building high-quality products and robust processes—crafting clean code, implementing best
                practices, and creating resilient systems that scale effortlessly. Our team is driven by a passion for
                innovation, an eye for excellence, and a commitment to pushing the future of money.
            </div>
        </div>
    );
}

export default Culture;